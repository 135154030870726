body {
  background-color: #eff2f5;
}

.test{
  position: relative;

  /* margin-top: -38px; */
  /* padding-right: 10px; */
}
.testIcona{
  position: absolute;
  z-index:99;
  margin-top: 22px;
  margin-left: 10px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 8px;
  background-color: white;
  border: 1px solid white;
}

::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-color: #cacaca;
}

.pagination a {
  margin: 0 5px;
  padding: 5px 10px;
  border-radius: 8px;
  color: black;
  background-color: #e1e5ee;
  text-decoration: none;
}

.pagination a:hover {
  background-color: #FF5758;
}

.pagination .active a {
  background-color:#FF5758 ;
  color: #fff;
  border-color: #FF5758;
}
@font-face {
  font-family: myFirstFont;
  src:url(../public/assets/font/Quicksand-SemiBold/Quicksand-SemiBold.ttf);

}

* {
  font-family: myFirstFont;
  color: #424549;
}

.mapLocation{
  width: 268px;
}


.iconServices{
  display: flex;
  /* flex-direction: column; */
  align-items: center;
}
.iconsForCursor{
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.oneTime{
  background-color: #FF5758;
}
.olaaa{
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.borderDiv{
   display: flex;
   justify-content: center;
  flex-wrap: wrap;
   border: 3px solid rgb(235, 227, 227);
   border-radius: 5px;
   padding: 2px 4px;
   margin-bottom: 4%;
   background-color: transparent;
  width: 50%;


}
.divForButtonAssign{
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  transform: translate(-2%, 800%);
  max-height: 879%;
  }
  .assignServices{
    border: 1px solid gray;
  background-color: #eff2f5;
  border-radius: 5px;
  padding: 5px 10px;
  color: rgb(92, 90, 90);
}
.passEye{
  margin-left: -6%;
  font-size: 13px;
  cursor: pointer;

}
@media only screen and (max-width: 800px) {
.passEye{
  margin-left: -13%
}
}